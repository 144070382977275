export default {
  title: {
    promotion: 'Promotion',
    illustration: 'Illustration',
  },
  label: {
    inviteDescription: 'This site offers games with an adventure experience.',
    onlineCustomerService: 'Online customer service',
    helpCenter: 'Help center',
    allGames: 'All',
  },
  games: {
    Slots: 'Slots',
    'Crash Game': 'Crash Game',
    Jackpots: 'Jackpots',
    'Table games': 'Table Games',
    Roulette: 'Roleta',
    'Vertical Thumbnails': 'Vertical Thumbnails',
    All: 'All',
    Favorite: 'Favorite',
    Home: 'Original game',
  },
  message: {},
};
