export default {
  title: {
    promotion: 'Promoção',
    illustration: 'Ilustração',
    downloadIos: 'Visite-nos no seu iPhone',
  },
  label: {
    inviteDescription: 'Este site oferece jogos com uma experiência de aventura.',
    onlineCustomerService: 'Atendimento ao cliente online',
    helpCenter: 'Centro de ajuda',
    allGames: 'Tudo',
  },
  games: {
    Slots: 'Caça-níqueis',
    'Crash Game': 'Colidir',
    Jackpots: 'Jackpots',
    'Table games': 'Jogos de mesa',
    Roulette: 'Roleta',
    'Vertical Thumbnails': 'Miniaturas verticais',
    All: 'Todos',
    Favorite: 'Coletar',
    Home: 'Jogo original',
  },
  message: {},
};
